
import lottie from "vue-lottie/src/lottie.vue"
import mainPageArticle from "@/components/articles/mainPageArticle.vue"
import * as animationData from "@/assets/animations/articles_animation_transparent.json"

export default {
  name: "Blog",
  components: { mainPageArticle, lottie },
  layout: (ctx) => (ctx.$device.isMobile ? "mobile" : "default"),
  data: () => ({
    lottieOptions: { animationData: animationData.default },
  }),
}
