
import MaterialCard from "@/components/base/MaterialCard"
import GoogleMap from "@/components/contactsParts/GoogleMap"
import MainPhones from "@/components/contactsParts/MainPhones"

export default {
  name: "Contacts",
  components: { MainPhones, GoogleMap, MaterialCard },
  beforeRouteEnter() {
    this.$fetch()
  },
  data: () => ({
    story: {},
    showDepartments: false,
    departments: [],
    email: "",
    fax: "",
    schedule: {},
    main_title: "",
  }),
  async fetch() {
    const res = await this.$storyapi.get("cdn/stories/pages/contacts", {
      version: "published",
      language: this.$i18n.locale !== "uk" ? this.$i18n.locale : "Default",
      cv: Date.now(),
    })
    this.story = res.data?.story
    this.departments = res.data?.story?.content?.Departments
    this.email = res.data?.story?.content?.email
    this.fax = res.data?.story?.content?.fax
    this.schedule = res.data?.story?.content?.schedule
    this.main_title = res.data?.story?.content?.main_title
    return { res }
  },
}
