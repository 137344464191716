
export default {
  name: 'Common',
  props: {
    story: {
      required: true,
      type: Object,
    },
  },
  methods: {
    getBlocks() {
      return this.story.content?.blocks
    },
    getTitle() {
      return this.story.content?.title
    },
  },
}
