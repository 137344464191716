
export default {
  name: "MainPageArticle",
  data: () => ({
    loading: false,
    story: {},
    maxLengthDescription: 500,
  }),
  async fetch() {
    const storyapigallery = "cdn/stories"
    const res = await this.$storyapi.get(storyapigallery, {
      starts_with: "articles",
      per_page: 1,
      page: 1,
      version: "published",
      language: this.$i18n.locale !== "uk" ? this.$i18n.locale : "Default",
      cv: Date.now(),
    })
    this.story = res.data.stories[0]
  },
  methods: {
    truncateArticleDescription(str) {
      return str.length > this.maxLengthDescription
        ? str.slice(0, this.maxLengthDescription) + "…"
        : str
    },
  },
}
