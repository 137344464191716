
import { mask } from "vue-the-mask"
import rules from "@/mixins/rules"
import MaterialCard from "~/components/base/MaterialCard.vue"

export default {
  name: "WorkWithUsForm",
  directives: {
    mask,
  },
  components: {
    MaterialCard,
  },
  // async fetch() {
  //   const res = await this.$storyapi.get('cdn/stories/pages/work-with-us', {
  //     version: 'published',
  //     language: this.$i18n.locale !== 'uk' ? this.$i18n.locale : 'Default',
  //     cv: Date.now(),
  //   })
  //   this.story = res.data.story
  //   return { res }
  // },
  mixins: [rules],
  props: {
    story: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    phone: "",
    name: "",
    valid: false,
    loading: false,
  }),
  computed: {
    getDescription() {
      return this.story?.content?.description || ""
    },
    getTitle() {
      return this.story?.content?.title || ""
    },
    getButton() {
      return this.story?.content?.button || ""
    },
  },
  methods: {
    sendForm() {
      this.loading = true
      this.$axios
        .post("/api/v2/bitrix/create-lead", {
          phone: this.phone,
          name: this.name,
        })
        .then(() => {
          this.$swal({
            title: this.$t("successfully"),
            text: this.$t("thank_you_for_contacting_us"),
            icon: "success",
            iconColor: "success",
            confirmButtonColor: "transparent",
            confirmButtonText: "Ок",
          })
        })
        .catch((error) => {
          // this.$sentry.captureException(error)
          this.$swal({
            title: "Error!",
            text: error.data,
            icon: "error",
            confirmButtonText: "Ок",
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
