
export default {
  name: "MainPhones",
  props: {
    story: {
      type: Object,
      required: true,
    },
    background: {
      type: String,
      default: "transparent",
    },
  },
  computed: {
    getTitle() {
      return this.story?.content?.phone_title || ""
    },
  },
  methods: {
    phoneGetter(phone) {
      return phone?.phone_number || ""
    },
    phoneGetterAHref(phone) {
      return phone?.phone_number?.replace(/\D/g, "") || ""
    },
    getPhones() {
      return this?.story?.content ? this?.story.content.phones : []
    },
    phoneIconGetter(phone) {
      return phone?.phone_icon?.filename || ""
    },
  },
}
