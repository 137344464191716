
import MaterialCard from "~/components/base/MaterialCard.vue"

export default {
  name: "AboutUs",
  components: {
    MaterialCard,
  },
  props: {
    block: {
      type: Array,
      required: true,
    },
  },
}
