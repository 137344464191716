
import lottie from "vue-lottie/src/lottie.vue"
import * as animationData from "@/assets/animations/work_with_us.json"
import WorkWithUsForm from "~/components/Forms/WorkWithUsForm"

export default {
  name: "WorkWithUs",
  components: { lottie, WorkWithUsForm },
  props: {
    story: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    lottieOptions: { animationData: animationData.default },
  }),
}
