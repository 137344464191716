
import LazyHydrate from 'vue-lazy-hydration'
import AboutUs from '@/components/DashboardParts/AboutUs'
import Common from '@/components/DashboardParts/Common'
import Contacts from '@/components/pages/Contacts'
import WorkWithUs from '@/components/DashboardParts/WorkWithUs'
import Blog from '@/pages/pages/blog.vue'

export default {
  name: 'MainPage',
  components: {
    LazyHydrate,
    WorkWithUs,
    Blog,
    Contacts,
    Common,
    AboutUs,
  },
  layout: (ctx) => (ctx.$device.isMobile ? 'mobile' : 'default'),
  scrollToTop: true,
  async asyncData({ app, store, i18n }) {
    return await app.$storyapi
      .get('cdn/stories/home', {
        version: 'published',
        resolve_links: 'story',
        language: i18n.locale,
        cv: Date.now(),
      })
      .then((res) => {
        return res.data
      })
      .catch((res) => {
        if (!res.response) {
          store.commit('storyblok/ACCESS_STATUS', 404)
        } else {
          store.commit('storyblok/ACCESS_STATUS', res.response.status)
        }
      })
  },
  data() {
    return {
      links: {},
      isActiveAboutUs: false,
      isActiveWorkWithUs: false,
      isActiveCommon: false,
      isActiveFeedback: false,
      isActiveContacts: false,
      title: 'Головна | Інтернет-магазин Astra-company',
      description:
        'ASTRA Company – продаж профнастилу, металочерепиці та металошиферу за цінами виробника. 29 років досвіду на ринку та власний імпорт. Професійна консультація та допомога в розрахунку необхідної кількості матеріалу. Виготовлення за індивідуальним замовленням. Широкий асортимент супровідної продукції і доставка по Україні',
    }
  },
  head() {
    return {
      title: this.$t('main_page'),
      meta: [
        { charset: 'utf-8' },
        {
          hid: 'description',
          name: 'description',
          content: this.description,
        },
        {
          hid: 'og:title',
          name: 'og:title',
          property: 'og:title',
          content: this.title,
        },
        {
          hid: 'twitter:card',
          name: 'twitter:card',
          property: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          hid: 'twitter:url',
          name: 'twitter:url',
          property: 'twitter:url',
          content: 'https://astra-company.com/',
        },
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          property: 'twitter:title',
          content: this.title,
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          property: 'twitter:description',
          content: this.description,
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          property: 'twitter:image',
          content: 'https://api.astra-company.com/storage/img/logo.svg',
        },
        {
          hid: 'fb:app_id',
          name: 'fb:app_id',
          property: 'fb:app_id',
          content: '546292466112453',
        },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: this.description,
        },
        {
          hid: 'og:url',
          name: 'og:url',
          property: 'og:url',
          content: 'https://astra-company.com/',
        },
        {
          hid: 'og:image',
          name: 'og:image',
          property: 'og:image',
          content: 'https://api.astra-company.com/storage/img/logo.svg',
        },
        {
          hid: 'og:image:secure_url',
          name: 'og:image:secure_url',
          property: 'og:image:secure_url',
          content: 'https://api.astra-company.com/storage/img/logo.svg',
        },
        {
          hid: 'og:image:width',
          name: 'og:image:width',
          property: 'og:image:width',
          content: 400,
        },
        {
          hid: 'og:image:height',
          name: 'og:image:height',
          property: 'og:image:height',
          content: 300,
        },
        {
          hid: 'og:image:type',
          name: 'og:image:type',
          property: 'og:image:type',
          content: 'image/svg',
        },
        {
          hid: 'og:image:alt',
          name: 'og:image:alt',
          property: 'og:image:alt',
          content: 'Logo Astra',
        },
        {
          hid: 'keywords',
          name: 'keywords',
          property: 'keywords',
          content: this.$t('description_local'),
        },
        {
          hid: 'contact',
          name: 'contact',
          property: 'contact',
          content: this.$t('сompany_location'),
        },
      ],
    }
  },
  mounted() {
    this.$store.dispatch('getStoryBlockCV')
  },
}
