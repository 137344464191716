export default {
  computed: {
    rules() {
      return {
        short: (v) => v >= 300 || this.$t("less_three_hundred"),
        min: (v) => (v && v.length >= 100) || this.$t("cant_be_less_hundred"),
        min_eight: (v) => (v && v.length >= 8) || this.$t("cant_be_less_eight"),
        long: (v) => v <= 6000 || this.$t("more_than_six_thousand"),
        longcherep: (v) => v <= 6200 || this.$t("not_be_more_six_thousand"),
        required: (value) => !!value || this.$t("required_field"),
        notnull: (value) => value > 0 || this.$t("cant_be_zero"),
        string: (value) => /^\d+$/.test(value) || this.$t("can_not_a_deadline"),
        longcherepVenesia: (v) =>
          v <= 6300 || this.$t("not_be_more_six_thousand"),
        emptryString: (value) => value !== "" || this.$t("cant_be_zero"),
        length: (value) =>
          (value && value.length >= 17) || this.$t("cant_be_less_ten"),
        cherep: (value) =>
          this.checkForCherep(value) || this.$t("multiple_of_the_metal"),
        stepten: (v) => v % 10 === 0 || this.$t("multiple_of_ten"),
      }
    },
    officialCodeRules() {
      return [
        (value) => !!value || this.$t("required_field"),
        (value) => value.length <= 10 || this.$t("can_be_less_ten"),
        (value) => value.length >= 8 || this.$t("more_or_equal_eight_signs"),
        (value) => value.length !== 9 || this.$t("cannot_be_nine_characters"),
      ]
    },
    emailRules() {
      return [
        (v) => !!v || this.$t("required_field"),
        (v) => /.+@.+/.test(v) || this.$t("valid_email"),
      ]
    },
  },
  methods: {
    checkForCherep(value) {
      if (value >= 450 && value <= 6200 && value < 4200) {
        const diff = value - parseInt(value / 350) * 350
        return diff >= 100 || diff === 0
      } else if (value >= 450 && value <= 6200) {
        const minusDelta = value - 3480
        const diff = minusDelta - parseInt(minusDelta / 360) * 360
        return diff >= 110 || diff === 0
      } else {
        return false
      }
    },
    checkForCherepByProduct(item) {
      return (
        item?.product?.erp_id === "00-00001981" ||
        item?.product?.erp_id === "00-00012044" ||
        item?.erp_id === "00-00001981" ||
        item?.erp_id === "00-00012044"
      )
    },
    hasVariableLength(item) {
      return item?.product?.trans_alt_unit !== "null"
    },
  },
}
